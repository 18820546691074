import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import { RODO, Navigation, Layout, Article, Pagination, SectionTitle, Menu } from '../components'
import { currentLanguage, t } from '../t/t'

const Content = styled.div`
  /* box-shadow: 0 4px 120px rgba(0, 0, 0, 0.1); */
  border-radius: 1rem;
  padding: 3rem 15rem;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding: 3rem 2rem;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.phone}) {
    padding: 2rem 1.5rem;
  }
  overflow: hidden;
`

const PinnedItem = () => (
  <Article
    title="Razem zrobimy bezpieczne aplikacje mobilne."
    date=""
    excerpt="Uczę pisać aplikacje mobilne. Robić to porządnie i zgodnie ze sztuką programowania. Ważne jest bezpieczeństwo i skalowalność. Zawsze musisz dać radę dostosować aplikację do zmieniającego się biznesu."
    timeToRead="1"
    slug="categories/programming"
    categories={['programming']}
    key="post.node.fields.slug"
    image="me1.jpg"
  />
)

const Index = ({ pageContext: { currentPage, prev, next }, data: { allMdx } }) => {
  const { edges } = allMdx

  return (
    <Layout>
      <Navigation currentLink="index" />
      <Menu />
      <RODO />
      <Content>
        <SectionTitle>{t[currentLanguage].latestStories}</SectionTitle>
        {edges.map((post) => (
          <Article
            title={post.node.frontmatter.title}
            date={post.node.frontmatter.date}
            excerpt={post.node.excerpt}
            timeToRead={post.node.timeToRead}
            slug={post.node.fields.slug}
            categories={post.node.frontmatter.categories}
            key={post.node.fields.slug}
          />
        ))}
        <Pagination prev={prev} next={next} slug={`/${currentPage - 1 === 0 ? '' : currentPage - 1}`} />
      </Content>
    </Layout>
  )
}

export default Index

Index.propTypes = {
  pageContext: PropTypes.shape({
    prev: PropTypes.string.isRequired,
    next: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      edges: PropTypes.array.isRequired,
      totalCount: PropTypes.number.isRequired,
    }),
  }).isRequired,
}

export const postQuery = graphql`
  query IndexPage($skip: Int!, $limit: Int!) {
    allMdx(sort: { fields: [frontmatter___date], order: DESC }, limit: $limit, skip: $skip) {
      edges {
        node {
          frontmatter {
            title
            date(formatString: "MM/DD/YYYY")
            categories
            image {
              publicURL
            }
          }
          fields {
            slug
          }
          excerpt(pruneLength: 200)
          timeToRead
        }
      }
    }
  }
`
